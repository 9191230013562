import { IFixedAssetDocumentSearch } from '../../entity/asset/IFixedAssetSearch';
import { IPagination } from '../../entity/IPagination';
import axiosInstance from '../../apis/arp';
import { IFixedAssetDocumentType } from '../../entity/asset/IFixedAssetDocumentType';
import { IAssetDocumentTotalSum } from '../../entity/asset/Documents/Document';
import { IFixedAssetDocumentItemSearch } from '../../entity/asset/IFixedAssetSearch';
import { IFixedAssetDocumentItem } from '../../entity/asset/IFixedAssetDocumentItem';
import { IFixedAssetDocument } from '../../entity/asset/IFixedAssetDocument';

export const getDocuments = (
  pagination: IPagination,
  search?: IFixedAssetDocumentSearch,
  documentTypeId?: string,
  organizationUuid?: string,
  fetchValues?: boolean,
  sort?: string,
  includeSums?: boolean
) => {
  return axiosInstance
    .get(`/assets/fixed-asset/documents`, {
      params: {
        organizationUuid,
        sort,
        fixedAssetDocumentTypeId: documentTypeId,
        fetchValues,
        includeSums,
        ...pagination,
        ...search,
      },
    })
    .then((response) => {
      return {
        documents: response.data.documents,
        totalRows: response.data.pagination.totalRows,
        total: response.data.total,
        totalFiltered: response.data.totalFiltered,
      } as {
        documents: IFixedAssetDocument[];
        totalRows: number;
        total: IAssetDocumentTotalSum;
        totalFiltered: IAssetDocumentTotalSum;
      };
    });
};

export const getDocument = (documentUuid: string) => {
  return axiosInstance
    .get(`/assets/fixed-asset/document/${documentUuid}`)
    .then((response) => {
      return {
        document: response.data,
      } as {
        document: IFixedAssetDocument;
      };
    });
};

export const getDocumentItems = (
  documentUuid: string,
  itemSearch: IFixedAssetDocumentItemSearch,
  pagination?: IPagination
) => {
  return axiosInstance
    .get(`/assets/document/${documentUuid}/items`, {
      params: pagination?.limit
        ? Object.assign(itemSearch, {
            limit: pagination.limit,
            offset: pagination.offset,
          })
        : itemSearch,
    })
    .then((response) => {
      return {
        documentItems: response.data.items,
        totalRows: response.data.pagination.totalRows,
      } as {
        documentItems: IFixedAssetDocumentItem[];
        totalRows: number;
      };
    });
};

export const getDocumentItem = (documentItemUuid: string) => {
  return axiosInstance
    .get(`/assets/fixed-asset/document/item/${documentItemUuid}`)
    .then((response) => {
      return {
        documentItem: response.data,
      } as {
        documentItem: IFixedAssetDocumentItem;
      };
    });
};

export const deleteDocumentItem = (documentItemUuid: string) => {
  return axiosInstance.delete(`/assets/fixed-asset/document/item/${documentItemUuid}`);
};

export const getDocumentType = (documentTypeUuid: string, organizationUuid: string) => {
  return axiosInstance
    .get(
      `/assets/fixed-asset/document-type/${documentTypeUuid}?organizationUuid=${organizationUuid}`
    )
    .then((response) => {
      return {
        documentType: response.data,
      } as {
        documentType: IFixedAssetDocumentType;
      };
    });
};

export const addItemsInBulk = (
  documentUuid: string,
  accountUuid?: string,
  accountableUuid?: string,
  locationUuid?: string
) => {
  const formData = new FormData();
  formData.set('accountUuid', accountUuid ? accountUuid : '');
  formData.set('latestAccountableUuid', accountableUuid ? accountableUuid : '');
  formData.set('latestLocationUuid', locationUuid ? locationUuid : '');
  return axiosInstance.post(
    `/assets/fixed-asset/document/${documentUuid}/items`,
    formData
  );
};

export const editDocumentItem = (
  item: IFixedAssetDocumentItem,
  documentItemUuid: string
) => {
  return axiosInstance.put(`/assets/fixed-asset/document/item/${documentItemUuid}`, item);
};

export const addItemToDocument = (
  item: IFixedAssetDocumentItem,
  documentUuid: string
) => {
  return axiosInstance.put(`/assets/fixed-asset/document/${documentUuid}/item`, item);
};

export const onEditDocumentSubmit = (data: IFixedAssetDocument) => {
  return axiosInstance.post(`/assets/fixed-asset/document`, data).then((response) => {
    return {
      uuid: response.data.uuid,
    } as {
      uuid: string;
    };
  });
};

export const getDocumentTypes = (organizationUuid: string) => {
  return axiosInstance
    .get(`/assets/fixed-asset/document-types`, {
      params: {
        organizationUuid,
      },
    })
    .then((res) => {
      return {
        documentTypes: res.data,
      } as { documentTypes: IFixedAssetDocumentType[] };
    });
};

export const onDocumentConclude = (documentUuid: string) => {
  return axiosInstance.post(`/wms/document/${documentUuid}/conclude`);
};

export const unlockDocument = (documentUuid: string) =>
  axiosInstance.post(`/wms/document/${documentUuid}/unlock`);
